import React, { useState, useEffect } from 'react';
import { defaultCar } from '../../../assets/images';
import { Carousel } from 'react-responsive-carousel';

const ImageDisplayer = ({ imageUrls, imageClasses, imageOnClick = () => {}, showThumbs=false, showStatus=false, showArrows=false,showIndicators=false }) => {
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(true); // Add loading state

  useEffect(() => {
    const loadImages = async () => {
      const loadedImages = await Promise.all(
        imageUrls.map((url) => 
          new Promise((resolve) => {
            const img = new Image();
            img.src = url;
            img.onload = () => resolve(url);
            img.onerror = () => resolve(defaultCar);
          })
        )
      );
      setImages(loadedImages);
      setLoading(false);
    };

    loadImages();
  }, [imageUrls]);

  if (loading) {
    // Render shimmer while images are loading
    return (
      <div className="carousel">
        <div className="shimmer" style={{ height: "300px", width: "100%" }}></div>
      </div>
    );
  }

  if(imageUrls.length > 1)
    return (
      <Carousel className='carousel' showArrows={showArrows} showStatus={showStatus} showThumbs={showThumbs} showIndicators={showIndicators}
      swipeable={true} 
      emulateTouch={true} 
      preventMovementUntilSwipeScrollTolerance={true}>
        {images.map((src, index) => (
          <div onClick={imageOnClick}>
            <img key={index} src={src} alt={`Image ${index}`} className={imageClasses}/>
          </div>
        ))}
      </Carousel>
    );
  return <img key={0} src={images[0]} alt={`Image 0`} className={imageClasses} />;
};

export default ImageDisplayer;
