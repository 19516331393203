import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import Image from '../Image';
import './index.scss';
import { hamburgerMenu, logo } from '../../../assets/images';
import UrlNavigatorSingleton from '../../lib/UrlNavigator';
import { ROUTES } from '../../route';
import { AccountBoxOutlined, FavoriteBorderOutlined, KeyboardArrowDown, Person2Outlined } from '@mui/icons-material';
import ConnectionAPI from '../../api';
import Button from '../Button';
import UserAPI from '../../lib/User';
import { useTranslation } from 'react-i18next';
import { isMobile } from '../../../utils/utils';
import { deleteStoredFilters } from '../../../forms/search';

const Header = (props) => {
    const location = useLocation();
    const [favorites, setFavorites] = React.useState(0);
    const { t, i18n } = useTranslation();
    const [isMenuOpen, setIsMenuOpen] = React.useState(false);

    React.useEffect(() => {
        /* Verify if Logged in */
        login();
    }, []);

    const login = async () => {
        let isAuth = await UserAPI.auth();
        getFavorites();
    }

    const getFavorites = async () => {
        const response = await ConnectionAPI.getFavorites();
        if (response.status === 200) {
            setFavorites(response.message.cars.length);
        }
    }

    const openModalProfile = () => {
        console.log('openModalProfile');
    };

    const emptyDropDown = (key) => {

    };

    const onChange = (key, value, parentField) => {

    };

    const findRoutePathByKey = (key) => {
        const recursiveFind = (routeArray, key) => {
            for (const route of routeArray) {
                if (route.key === key) {
                    return route.path;
                }
                if (route.children) {
                    const foundPath = recursiveFind(route.children, key);
                    if (foundPath) return foundPath;
                }
            }
        };
        return recursiveFind(ROUTES, key);
    };

    const renderNavLink = ({ path, breadcrumb, key }) => {
        const isActive = key === 'home' ? location.pathname === '/' : UrlNavigatorSingleton.isInPage(key);

        return (
            <Link
                key={key}
                to={path}
                className={`inline ${isActive ? 'isActiveHeader' : ''}`}
                onClick={() => {
                    deleteStoredFilters();
                }}
            >
                <h5 className={``}>{t(breadcrumb)}</h5>
            </Link>
        );
    };

    const showMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    }

    const isUserLoggedIn = props.user && props.user.id;
    const username = /* Two first letters in uppercase */ isUserLoggedIn ? String(props.user.name).toUpperCase().slice(0, 2) : '';

    const mobile = isMobile(window);

    useEffect(() => {
        if(!mobile)
            setIsMenuOpen(true);
    },[mobile]);
    return (
        <>
            <div className={`${mobile ? 'wrapper z-50 fixed items-center justify-center' : ''}`}>
                <div className={`header ${mobile ? '' : 'fixed'} z-50 ${isMenuOpen && mobile ? 'header-open' : ''}`}>
                    <div className={`inline ${mobile ? 'center' : ''}`}>
                        {
                            mobile ?
                                <img src={hamburgerMenu} height={16} onClick={showMenu} />
                                :
                                <></>
                        }
                        <Link to='/' className='logo'>
                            <Image src={logo} height='100%' width='100px' />
                        </Link>
                    </div>
                    {isMenuOpen ?
                        <>
                            <div className='nav-links'>
                                {ROUTES.filter(route => route.header).map(route => renderNavLink(route))}
                                {ROUTES.filter(route => route.children).flatMap(route =>
                                    route.children.filter(child => child.header).map(child =>
                                        renderNavLink({ ...child, path: `${route.path}/${child.path}` })
                                    )
                                )}
                            </div>
                            {
                                !mobile ? 
                                <div className='account-header-container'>
                                    {isUserLoggedIn ?
                                        <div className='flex items-center justify-center'>
                                            <Link className='inline' to={'/app/favorites'}>
                                                <FavoriteBorderOutlined className='favorite-icon' />
                                                <h5 className='favorite-count'>{favorites}</h5>
                                            </Link>
                                            <Link to='/app/account' className='inline'>
                                                <h5 className='username-account-icon'>{username}</h5>
                                            </Link>
                                        </div>

                                        :
                                        <Button className={'button fit icon-button'} style={{ marginLeft: 8 }} onClick={() => UrlNavigatorSingleton.__toPage('/signup')}>
                                            <Person2Outlined className='icon' />
                                            <h4 className='inline'>{t('login')}</h4>
                                        </Button>
                                    }
                                    {/*<button className='inline' onClick={openModalProfile}>
                                        <h4 className='text'>Profile</h4>
                                        <KeyboardArrowDown className='' style={{marginTop : 2}}/>
                                    </button>*/}
                        </div> 
                    :
                    <></>
                }
                        </>
                        : <></>
                    }
                </div>
                {
                    mobile ? 
                    <div className='account-header-container'>
                                    {isUserLoggedIn ?
                                        <div className='center-mobile inline items-center justify-center'>

                                            <Link className='inline' to={'/app/favorites'}>
                                                <FavoriteBorderOutlined className='favorite-icon' />
                                                <h5 className='favorite-count'>{favorites}</h5>
                                            </Link>
                                            <Link to='/app/account' className='inline'>
                                                <h5 className='username-account-icon'>{username}</h5>
                                            </Link>
                                        </div>

                                        :
                                        <Button className={'center-mobile-icon button fit icon-button'} style={{ marginLeft: 8 }} onClick={() => UrlNavigatorSingleton.__toPage('/signup')}>
                                            <Person2Outlined className='icon' />
                                        </Button>
                                    }
                                    {/*<button className='inline' onClick={openModalProfile}>
                                        <h4 className='text'>Profile</h4>
                                        <KeyboardArrowDown className='' style={{marginTop : 2}}/>
                                    </button>*/}
                    </div> 
                    :
                    <></>
                }
            </div>
            <div className='header-margin' />
        </>
    );
};

function mapStateToProps(state) {
    return {
        user: state.user,
        router: state.router,
        app: state.app
    };
};

export default connect(mapStateToProps)(Header);
