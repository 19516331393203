import './index.scss';
import { Component } from 'react';
import { connect } from 'react-redux';

class InputText extends Component{
    constructor(props){super(props); this.state = {isFocused : true}}

    onChange = (e) => {
        const { keyItem, parentField, defaultValue, defaultOnEmpty } = this.props;
        let __normalizedText = e.target.value;
        if(keyItem == ('email' || 'password')){
            __normalizedText = __normalizedText.trim();
        }

        if(!__normalizedText && defaultOnEmpty)
            __normalizedText = defaultValue;

        if(parentField){
            this.props.onChange(keyItem, __normalizedText, parentField);
        }else{
            this.props.onChange(keyItem, __normalizedText);
        }
    }

    handleBlur = () => {
        this.setState({isFocused : false})
    }
    render = () => {
        const { isFocused } = this.state;
        var { placeholder, title, type, error, isValid=true, form, parentField,keyItem,defaultValue, isRequired } = this.props;
        isValid = isFocused ? true : isValid;
        let inputPresent = null; 
        if(form){
            inputPresent = parentField ? form[parentField][keyItem] : form[keyItem];
        }
        if(!inputPresent)
            inputPresent = defaultValue;

        return (
            <div className='inputTextDiv'>                
                <span className='inline'>
                    <h5 className={`title bold-text ${!isValid ? 'error-text' : ''}`}>{title}</h5>
                    {isRequired ? <h5 className='!text-red-600 !pt-1 !pb-0 !pl-0 !pr-0'>*</h5> : null}
                </span>

                <input className={`${!isValid ? 'error-box' : ''}`}
                    onChange={this.onChange} 
                    value={inputPresent}
                    onBlur={this.handleBlur}
                    type={type} 
                    placeholder={placeholder}>
                </input>
                {!isValid ? <h5 className='input-error'>{error}</h5> : null}
            </div>
            )
    }
    
}

function mapStateToProps(state) {
    const user = state.user;
    return {
        user
    };
  }

  export default connect(mapStateToProps)(InputText);
