import APIConnection from "../api"
import store from "../redux/store";
import { setUser } from "../redux/actions/user";
import CacheSingleton from "./Cache";
import { removeEmptyArraysAndKeys, roundUpIfNotInteger } from "./Helpers";
import NotificationHandlerSingleton from "./NotificationHandler";

class User{
    constructor(){
        this.isLoaded = false;
        this.user = {};
        this.tops = {}
    }

    load = async () => {
        await this.__updateUser();
        this.isLoaded = true;
        return true;
    }

    start = async () => {
        await this.__loadInitialDataAsync();
    }

    __loadInitialDataAsync = async () => {

    }

    auth = async () => {
        let user = CacheSingleton.getObject("auth");
        if(user && user.id && user.bearerToken){
            let res = await APIConnection.auth({
                user_id     : user.id, 
                bearerToken : user.bearerToken
            });
            if(res.status == 200){
                this.user = { ...res.message}
                await this.load();
                return true;
            }else{
                NotificationHandlerSingleton.onError({type : "Authentication", err : res})
            }
        }else{
        }
    }

    googleLogin = async ({googleBearerToken}) => {
        let response = await APIConnection.googleLogin({
             googleBearerToken
        });
        const { message, status } = response;
        if(status != 200){
            NotificationHandlerSingleton.onError({type : "Google Login", err : response})
        }else{
            // Set to cache Login Info
            CacheSingleton.saveObject("auth", 
            {
                id    : message.id,
                bearerToken : message.bearerToken
            }, 10000);
            this.user = message;
            await this.auth();
        }
        return response;
    }
    
    register = async ({name, password, email}) => {
        let response = await APIConnection.register({
            name        : name, 
            password    : password,
            email       : email
        });
        const { message,status } = response;
        if(status != 200){NotificationHandlerSingleton.onError({type : "Login", err : response})}
        else{await this.login({email, password})};
        return response;
    }

    requestResetPassword = async ({email}) => {
        let response = await APIConnection.requestResetPassword({  
            email : email
        });
        const { message,status } = response;
        // Show on Modal Information
        if(status != 200){NotificationHandlerSingleton.onError({type : "Reset Password", err : response})}
        return response;
    }

    resetPassword = async ({password, token, email}) => {
        let response = await APIConnection.resetPassword({
            password : password,
            token : token,
            email : email
        });
        const { message,status } = response;
        if(status != 200){NotificationHandlerSingleton.onError({type : "Reset Password", err : response})}
        return response;
    }


    login = async ({email, password}) => {
        let response = await APIConnection.login({
            email : email, 
            password : password
        });
        const { message,status } = response;
        if(status != 200){NotificationHandlerSingleton.onError({type : "Login", err : response})}
        else{
            // Set to cache Login Info
            CacheSingleton.saveObject("auth", 
            {
                id    : message.id,
                bearerToken : message.bearerToken
            }, 10000);

            this.user = message;
            await this.auth();
        }
        return response;
    }

    logout = async () => {
        CacheSingleton.remove("auth");
        this.user = {};
        await this.__updateUser();
    }

   
    __updateUser = async () => { 
        /* Add User Info */
        await store.dispatch(setUser({
            ...this.user
        }));
    }

}

const UserAPI = new User();

export default UserAPI;