import React,{ Component } from 'react';
import { connect } from 'react-redux';
import './index.scss'
import variables from '../../../../_export.scss';
import { IconButton } from '@material-ui/core';
import { CloseOutlined, KeyboardArrowDown } from '@mui/icons-material';
import Button from '../../Button';
import { MOBILE_WIDTH } from '../../..';
import { withTranslation } from 'react-i18next';

class ContainerDropDown extends Component{
    constructor(props){
        super(props);
        const { keyItem, id } = this.props;

        this.state = {
            isSelected : false,
            clickedOutside: false,
        }
        this.ref = React.createRef();
    }

	handleChange = (event) => {
		this.setState({item : event.target.value});
	};
    
    changeDropDownStatus = () => {
        this.setState({isSelected : !this.state.isSelected})
    }
    
    openDropDownStatus = () => {
        const{ id,keyItem } = this.props;
        this.setState({isSelected : true});
        document.getElementById(`modal-${this.props.keyItem}-${id}`).classList.replace('hideModal', "showModal");


        let comp = document.getElementById("input" + keyItem);
        if(comp){
            comp.focus();
        }
    }
    
    emptyDropDown = () => {
       this.props.emptyDropDown(this.props.keyItem);
    }

    componentDidMount() {
        document.addEventListener("mousedown", this.onFocusLost);
    }
    
    componentWillUnmount() {
        document.removeEventListener("mousedown", this.onFocusLost);
    }

    onFocusLost = (e) => {
        const { isSelected,dropDownId } = this.state;
        if (!this.ref.current.contains(e.target) && isSelected) {
            this.setState({ clickedOutside: true });
            this.changeDropDownStatus();
        }
    };

    render = () => {
		const { title, form, keyItem, itemSelected, iconTitle, id, placeholder, parentField, removeX=false } = this.props;
        const { isSelected } = this.state;

        var filters;
        if(parentField){
            filters = form[parentField];
        }else{
            filters = form;
        }
        let isActive = (filters[keyItem] != '' || filters[keyItem].length > 0);
        if(removeX){
            isActive = false;
        }

        let isMobile = window.innerWidth < MOBILE_WIDTH;

        return (
			<div className='container-multiSelect modal-selector'>
                <div className={`selected-button ${isActive ? 'activeButton' : null}`} onClick={this.openDropDownStatus}>
					{itemSelected ? 
                        <h5 className='inline'>{itemSelected}</h5>
                    :
					<h5 className='inline placeholder'>
						{isMobile ? <div className='icon-multiSelect grey'>{iconTitle}</div> : placeholder}
					</h5>
                    }
                    {isActive && !removeX ? 
                        <IconButton style={{margin : 0, padding : 0, height : 25}} onClick={this.emptyDropDown}>
                            <CloseOutlined style={{color : variables.backgroundColor, marginLeft : 0, marginTop : 10}}/>
                        </IconButton>
                    :
                    <div className='inline no-margin'>
                        {/* {isMobile ? null :  */}
                            <KeyboardArrowDown style={{color : variables.textColor}}/>
                        {/* } */}
                    </div>}
				</div>
                <div ref={this.ref} 
                    id={`modal-${keyItem}-${id}`} className={`div-multiSelectBody modal ${isSelected ? 'showModal' : 'hideModal'}`} >
                    <div className='children-container'>
                        {this.props.children}
                    </div>
                    <div className='clear-box-container'>
                        <Button onClick={this.changeDropDownStatus} className='btn close-button thin-btn button'>
                            <h5>Fechar</h5>
                        </Button>
                    </div>
                </div>
		  	</div>
        )
    }
    
}

export default ContainerDropDown;
