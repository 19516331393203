import './index.scss';
import { Component } from 'react';
import CardHorizontal from '../../../components/Card/Horizontal';
import PaginationContainer from '../../../components/Pagination';
import Loading from '../../../components/Loading';
import { KeyboardArrowDown } from '@mui/icons-material';
import SelectDropDown from '../../../components/DropDown/Select';
import { sortOptions } from '../../../../filters/sort';
import { withTranslation } from 'react-i18next';


class CarsListVertical extends Component {

    constructor(props) {
        super(props);
        const { t } = this.props;

        this.state = {
            form : {
                filters : {
                    sort : Array.from(sortOptions(t).keys())[0],
                }
            }
        };
    }
   
   onChange = (key, value, parentField) => {
        const { form } = this.state;
        const { t } = this.props;
        form[parentField][key] = value;
        this.setState({ form });
        this.props.changeSort(sortOptions(t).get(value));
    }

    emptyDropDown = (key) => {
        const { form } = this.state;
        form.filters[key] = '';
        this.setState({ form });
    }

    render() {
        const { loading, cars, title, total, currentPage, totalPages, onChangePage, isOrder, t, initialSortIndex } = this.props;
        const { form } = this.state;
        const hasCars = cars && cars.length > 0;
        
        if(initialSortIndex){
            form.filters.sort = Array.from(sortOptions(t).keys())[initialSortIndex];
        }
            
        return (
            <div className='small-container'>
                {loading && <Loading />}
                <div className='scroll-container-car'>
                    <div className='small-container flex'>
                        <div>
                            <h2>{title}</h2>
                            <h5 className='inline'>{total}</h5>
                            <h5 className='grey inline' style={{ marginBottom: 3, marginLeft: -1 }}>{t('results')}</h5>

                            {
                                hasCars ?
                                <div className='right-sort !overflow-visible'>
                                    <SelectDropDown 
                                        id={1}
                                        removeX={true}
                                        form={form}
                                        parentField={'filters'}
                                        keyItem={'sort'}
                                        emptyDropDown={this.emptyDropDown}
                                        onChange={this.onChange}
                                        list={Array.from(sortOptions(t).keys())} 
                                        title={t('sort')}    
                                    />
                                </div>
                                :
                                <></>
                            }
                            
                        </div> 
                    </div>
                    <div className=''>
                        {hasCars ? cars.map((car, index) => (
                            <CardHorizontal key={index} car={car} isOrder={isOrder} />
                        )) : <p>{t('buy_2')}</p>}
                    </div>
                </div>
              
                <PaginationContainer page={currentPage} pages={totalPages} onChangePage={onChangePage} />
            </div>
        );
    }
}

export default (withTranslation()(CarsListVertical));
