import './index.scss';
import { Component } from 'react';
import { connect } from 'react-redux';
import Loading from '../../components/Loading';
import { MetaTags } from 'react-meta-tags';
import Header from '../../components/Header';
import ThreeItems from '../../sections/ThreeItems';
import Footer from '../../components/Footer';
import FilterView from '../../sections/FilterView';
import CarsListVertical from '../../sections/CarsList/Vertical';
import ConnectionAPI from '../../api';
import { getObjectFromQueryString, getQueryStringFromObject } from '../../lib/Helpers';
import { scrollToTopPage } from '../Utils/utils';
import { withTranslation } from 'react-i18next';
import { isMobile } from '../../../utils/utils';
import { getCarSortFilter, setCarSortFilter } from '../../../forms/search';

class BuyPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            cars: [],
            total: 0,
            filters : {},
            currentPage: 1,
            totalPages: 0,
            vehicleFilters: {}
        };
    }

    componentDidMount() {
        //always start at the top
        scrollToTopPage();
        let aux = getCarSortFilter();
        if(aux)
            this.state.filters.sort  = aux.sort;
    }
    
    getCars = async (page) => {
        const { filters,vehicleFilters,currentPage } = this.state;
        if(!page)
            page = currentPage;
        let searchFilters = { ...filters, ...vehicleFilters };

        searchFilters.offset = (page-1) * 20;
        this.setState({ loading: true, filters });

        try {
            //console.log(searchFilters);
            const response = await ConnectionAPI.searchCars({ filters: searchFilters });

            const { cars, total } = response.message;
            const totalPages = Math.ceil(total / 20); // Assuming 20 cars per page
            this.setState({ cars, loading: false, total, currentPage: page, totalPages });
            scrollToTopPage();
        } catch (error) {
            console.log(error);
            this.setState({ loading: false });
        }
    }

    onChangePage = (event, page) => {
        this.getCars(page);
    }
    
    changeSort = (sort) => {
        this.state.filters.sort = sort;
        setCarSortFilter(sort);
        this.getCars();
    }

    changeVehicleFilter = async (newVehicleFilters) => {
        this.state.vehicleFilters = newVehicleFilters;
        await this.getCars();
    }

    render() {
        const { t } = this.props;
        const { loading, cars, total, currentPage, totalPages } = this.state;

        const mobile = isMobile(window);
        return (
            <>
                {loading && <Loading />}
                <MetaTags>
                    <title> Versicar </title>
                    <meta name="description" content="Buy your best car" />
                    <meta property="og:title" content="Versicar" />
                </MetaTags>
                <Header />
                <div className='box-background section'>
                    <div className={`${mobile ? '' : 'grid-filter-car sub'}`}>
                        <FilterView changeVehicleFilter={this.changeVehicleFilter}
                                    getCars={this.getCars} />
                        <CarsListVertical
                            changeSort={this.changeSort}
                            title={t('buy_1')}
                            total={total}
                            cars={cars}
                            loading={loading}
                            currentPage={currentPage}
                            totalPages={totalPages}
                            onChangePage={this.onChangePage}
                            initialSortIndex={this.state.filters.sort - 1}
                        />
                    </div>
                </div>
                <Footer />
            </>
        );
    }
}

function mapStateToProps(state) {
    const user = state.user;
    return { user };
}

export default connect(mapStateToProps)(withTranslation()(BuyPage));