/* Create a Google Reviews Section for the Reviews Page for Cars bought, add fake data */
import React from 'react';
import './index.scss';
import CardVertical from '../../components/Card/Vertical';
import { useTranslation } from 'react-i18next';

// const cardsData = [
//   {
//     image: 'path/to/image.jpg',
//     reviewer: 'Rodrigo S.',
//     rating: 5,
//     text: 'Very efficient. Impeccable and very professional customer service (even in managing buyers\' anxieties!). Car delivered to the front door within the agreed time. Advised',
//     carModel: 'Mercedes AMG',
//   },
//   {
//     image: 'path/to/image.jpg',
//     reviewer: 'Rodrigo S.',
//     rating: 5,
//     text: 'Very efficient. Impeccable and very professional customer service (even in managing buyers\' anxieties!). Car delivered to the front door within the agreed time. Advised',
//     carModel: 'Mercedes AMG',
//   },
// {
//     image: 'path/to/image.jpg',
//     reviewer: 'Rodrigo S.',
//     rating: 5,
//     text: 'Very efficient. Impeccable and very professional customer service (even in managing buyers\' anxieties!). Car delivered to the front door within the agreed time. Advised',
//     carModel: 'Mercedes AMG',
//   },
// ];

const Cards = ({reviews}) => {
  const cardsData = []
  for(let i = 0; i < reviews.length; i++){
    cardsData.push({
      image: 'path/to/image.jpg',
      reviewer: reviews[i].reviewer,
      rating: reviews[i].rating,
      text: reviews[i].text,
      carModel: null,
    });
  }
  return (
    <div className="card-container">
      {cardsData.map((card, index) => (
        <CardVertical key={index} {...card} />
      ))}
    </div>
  );
};


const GoogleReviews = ({reviews}) => {
  const { t, i18n } = useTranslation();
    return (
        <div>            
            <div className='section'>
                <div className='container'>
                    <div className='small-container'>
                        <h4 className='primary-text center'>Google {t('review_1')}</h4>
                        <h2 className='center'>{t('review_3')}</h2>
                    </div>
                    <div className='small-container container'>
                      {/* Add a list of cards with reviews from the GOOGLE API */}
                        <Cards reviews={reviews}/>
                    </div>
                </div>
            </div>
        </div>
    )
}   


export default GoogleReviews;