import ReactPixel from 'react-facebook-pixel';

export const pixelUserSignup = (username, email) => {
    ReactPixel.track('UserSignup',
        {
            value: {name: username, email: email}
        }
      );
}

export const pixelUserFavorite = (username, email, carId) => {
    ReactPixel.track('UserFavorite',
        {
            value: {name: username, email: email, carId : carId}
        }
      );
}

export const pixelUserMobilePhone = (username, email) => {
    ReactPixel.track('UserMobilePhone',
        {
            value: {name: username, email: email}
        }
      );
}