export const sortOptions = (t) => {
    const sortOptionsObject = {};
    sortOptionsObject[t('newest')] = 1;
    sortOptionsObject[t('lowest_price')] = 2;
    sortOptionsObject[t('highest_price')] = 3;
    sortOptionsObject[t('lowest_mileage')] = 4;
    sortOptionsObject[t('highest_mileage')] = 5;
    sortOptionsObject[t('lowest_registration')] = 6;
    sortOptionsObject[t('highest_registration')] = 7;
    return new Map(Object.entries(sortOptionsObject));
};
//export const sortOptions = ['Newest', 'Lowest Price', 'Highest Price', 'Highest Mileage', 'Lowest Mileage', 'Registration Date (Lowest)', 'Registration Date (Highest)'];
