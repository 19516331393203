// Assuming the necessary files and configurations are correctly set up in your project
import React from 'react';
import { createRoot } from "react-dom/client";
import '../index.scss'; // Ensure your CSS variables are defined here or in an imported file
import reportWebVitals from '../reportWebVitals';
import App from './app';
import { Provider } from 'react-redux';
import store from './redux/store';
import MobileSwiper from './components/MobileSwiper';
const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

export const MOBILE_WIDTH = 500;

// Initialize Google Analytics and Facebook Pixel
//GoogleAnalyticsSingleton.initialize();
//FacebookPixelSingleton.initialize();
root.render(
  <Provider store={store}>
    <MobileSwiper>
      <App />
    </MobileSwiper>
  </Provider>
);

reportWebVitals();