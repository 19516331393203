/* Create Hero Section with Image and Text */
import React, {useState,useEffect} from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Button from '../../components/Button';
import './index.scss';
import CheckBox from '../../components/CheckBox';
import { hero } from '../../../assets/images';
import { EnhancedEncryptionOutlined, FavoriteBorderOutlined, SupportOutlined } from '@mui/icons-material';
import Select from '../../components/DropDown/Select';
import { mainFormSearch } from '../../../forms/mainsearch';
import { filtersSearch } from '../../../filters/search';
import { getQueryStringFromObject,renderItemList } from '../../lib/Helpers';
import ConnectionAPI from '../../api';
import { useTranslation } from 'react-i18next';
import InformationCard from '../../components/InformationCard';
import Loading from '../../components/Loading';
import { isMobile } from '../../../utils/utils';
import { deleteStoredFilters } from '../../../forms/search';

const formDefault = mainFormSearch();

const MainHero = () => {
    const [form, setForm] = useState(formDefault);
    let [offers, setOffers] = useState('');
    let [allFilters, setAllFilters] = useState(null);
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();

    const emptyDropDown = (subType) => {
        if(subType == 'make')
            form.filters.model = '';
        setForm({...form, filters : {...form.filters, [subType] : ''}});
    };
    
    const search = () => {
        /* Go to Buy Page and add search params */
        /* Set Query Paras from Form */
        const query = getQueryStringFromObject({filters : form.filters});
        navigate(`/buy?${query}`);
    };

    const onChange = async (key, value, parentField, options={variable : false, array : false}) => {
        if(options.array){
            if(options.variable){
                const array = this.state.form[parentField][key];
                if(array.includes(value)){
                    const index = array.indexOf(value);
                    array.splice(index, 1);
                }else{

                    array.push(value);
                }
                setForm({...form, [parentField] : {...[parentField], [key] : array}});
            }else{
                setForm({...form, [parentField] : {...form[parentField], [key] : [value]}});
            }
        }else{
            setForm({...form, [parentField] : {...form[parentField], [key] : value}});
        }
    }

    const renderItemListFilter = ({ title, placeholder, keyItem, parentField, search, allowNewOption }) => {
        return (
                renderItemList({
                    form:form,
                    parentField:parentField,
                    keyItem:keyItem,
                    emptyDropDown:emptyDropDown,
                    onChange:onChange,
                    placeholder:placeholder,
                    allFilters:allFilters,
                    title:title,
                    search:search,
                    allowNewOption:allowNewOption
                    }
                )
        );
    };

    useEffect(() => {
        async function getOffers() {
            let res = await ConnectionAPI.getNumberOfOffers();
            setOffers(res.message.length);
        };
        
        async function setFilters() {
            const filters = await filtersSearch(t,i18n.language, form.filters.make);
            setAllFilters(filters);
        };

        // You need to restrict it at some point
        // This is just dummy code and should be replaced by actual

        if(offers == '')
            getOffers();
        
        setFilters();

      }, [form]);

    const mobile = isMobile(window);
    return (
        <>{allFilters == null ? <Loading /> : 
            <div>
                <div className='main-hero'>
                    <div className='background'></div>
                    <img src={hero} alt='hero' className='hero-image' />
                    <div className='content'>
                        <div className='container'>
                            <h2 className='main-hero-title'>{t('home_1')}</h2>
                            <h2 className='main-hero-subtitle'>{t('home_2')}</h2>
                            <div className='small-container w-full'>
                                <div className={mobile ? '' : 'grid-33'}>
                                    <div className=''>
                                        {renderItemListFilter({ title: t('make'), placeholder: t('make'), keyItem: 'make', parentField: 'filters'
                                            , search:true})}
                                    </div>
                                    <div className=''>
                                        {renderItemListFilter({ title: t('model'), placeholder: t('model'), keyItem: 'model', parentField: 'filters'
                                            , search:true
                                        })}
                                    </div>
                                    <div className=''>
                                        {renderItemListFilter({ title: t('fuel'), placeholder: t('fuel'), keyItem: 'fuel', parentField: 'filters'})}
                                    </div>
                                </div>
                                <div className={mobile ? '' : 'grid-33'}>
                                    <div className='pt-5'>
                                        <h5 className='bold-text'>{t('registration')}</h5>
                                        <div className='grid-50'>
                                            {renderItemListFilter({keyItem : 'registrationFrom',  placeholder : t('from'), parentField : 'filters', search: true})}
                                            {renderItemListFilter({keyItem : 'registrationTo',  placeholder : t('to'), parentField : 'filters', search: true})}
                                        </div>
                                    </div>
                                    <div className='pt-5'>
                                        <h5 className='bold-text'>{t('price')} (EUR)</h5>
                                        <div className='grid-50'>
                                            {renderItemListFilter({keyItem : 'priceFrom', placeholder : t('from'), parentField : 'filters', search: true,allowNewOption: true})}
                                            {renderItemListFilter({keyItem : 'priceTo', placeholder : t('to'), parentField : 'filters', search: true,allowNewOption: true})}
                                        </div> 
                                    </div>
                                </div>
                                <div className='grid-33 small-container items-center'>
                                    <div className='left'>
                                        <Link to='/buy' onClick={() => deleteStoredFilters()}>
                                            <h5 className='link'>{t('home_3')}</h5>
                                        </Link>
                                    </div>
                                    <Button className='button fit ml-auto mr-0 col-start-3' onClick={search}>
                                        <h4>{offers + ` ${t('offers')}`}</h4>
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='section box-background z-0'>
                    <div className={`container ${mobile ? '' : 'grid-33'}`}>
                        <InformationCard center={true} title={<FavoriteBorderOutlined fontSize='large' />} 
                            subTitle={t('home_4')} content={t('home_5')}/>
                        <InformationCard center={true} title={<EnhancedEncryptionOutlined fontSize='large' />} 
                                subTitle={t('home_6')} content={t('home_7')}/>
                        <InformationCard center={true} title={<SupportOutlined fontSize='large' />} 
                                subTitle={t('home_8')} content={t('home_9')}/>
                    </div>
                </div>
            </div>
        }</>
    );
};

export default MainHero;
