import './index.scss';
import { Component } from 'react';
import { connect } from 'react-redux';
import Loading from '../../components/Loading';
import APIConnection from '../../api';
import { MetaTags } from 'react-meta-tags';
import MainHero from '../../sections/MainHero';
import Header from '../../components/Header';
import HowItWorksContainer from '../../sections/HowItWorksContainer';
import ThreeItems from '../../sections/ThreeItems';
import Footer from '../../components/Footer';
import FAQContainer from '../../sections/FAQContainer';

class LandingPage extends Component{
    constructor(props){super(props);this.state = {loading : false}}

    render = () => {
        const { loading } = this.state;
        
        return (
            <>  
                <>{ (loading) ? <Loading/> : null}</>
                <MetaTags>
                    <title> Versicar </title>
                    <meta name="description" content="Buy your best car" />
                    <meta property="og:title" content="Versicar" />
                </MetaTags>
                {/* Header */}
                <Header/> 
                {/* Main Container */}
                <MainHero/>
                {/* FAQ Container */}
                <a id="faq">
                    <FAQContainer backgroundType='secondary'/>
                </a>
                {/* <ThreeItems/> */}
                {/* Footer */}
                <Footer/>
            </>
            )
    }
    
}

function mapStateToProps(state) {
    const user = state.user;
    return {
        user
    };
  }

  export default connect(mapStateToProps)(LandingPage);
