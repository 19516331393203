
import React, { Component, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import './index.scss'
import ContainerDropDown from '../Container';

class SelectDropDown extends Component{
    constructor(props){
        super(props);
        this.state = {
            currentList: this.props.list,
            originalList: this.props.list,
        }
    }
	
	handleChange = async (name) => {
        const { keyItem, parentField, options={} } = this.props;
		this.setState({item : name});
        this.changeDropDownStatus();
		await this.props.onChange(keyItem, name, parentField, options);   
	};
	
	emptyDropDown = () => {
		this.props.emptyDropDown(this.props.keyItem);
	}
    
    changeDropDownStatus = () => {
        this.container.changeDropDownStatus();
    }

    onSearchChange = (event) => {
        let search = event.target.value;
        let { currentList } = this.state;
        let aux = []
        if(search.length == 0)
            aux = this.props.list;
        else{
            search = search.toLowerCase();

            for(let i = 0; i < currentList.length; i++){
                if((currentList[i] + "").toLowerCase().startsWith(search)){
                    aux.push(currentList[i]);
                }
            }  
        }
        
        if(this.props.allowNewOption){
            aux.unshift(search);
        }
        this.setState({currentList: aux})
    }


    
    render = () => {
        const { title, list, form, keyItem, id, placeholder, parentField
            , removeX, isSearch, isRequired, noMargin} = this.props;
        let { item, currentList, originalList } = this.state;

        if(form[parentField] != '' || form[parentField].length > 0){
            item = form[parentField][keyItem];
        }

        if(originalList.length != list.length){
            this.state.originalList = list;
            this.state.currentList = list;
            this.state.item = '';
            currentList = this.state.currentList;
            item = this.state.item;
        }

        return (
            <div className='box-container no-border no-padding !bg-transparent' style={{marginTop : noMargin ? 0 : 16}}>
                {
                    title ?
                    <span className='inline'>
                        <h5 className={'title bold-text'}>{title}</h5>
                        {isRequired ? <h5 className='!text-red-600 !pt-0 !pb-0 !pl-1 !pr-0'>*</h5> : null}
                    </span>
                    :
                    <div></div>
                }
                
                <ContainerDropDown    
                    id={id}
                    parentField={parentField}
                    placeholder={placeholder}
                    emptyDropDown={this.emptyDropDown} 
                    itemSelected={item}
                    keyItem={keyItem}
                    removeX={removeX}
                    form={form} 
                    title={title}
                    ref={(child) => this.container = child}
                    >
                    <div>
                        {
                            isSearch ? <div>
                                            <input id={"input" + keyItem} onChange={this.onSearchChange} className="itemInput"/>
                                        </div>
                                    :<div></div>
                        }
                        
                        <div>
                            {currentList.map( (item, index) => {
                                return (
                                    <h5 onClick={async (e) => await this.handleChange(item,e)} className='itemlistselect' key={index}> 
                                    {item} 
                                    </h5>
                                )
                            })}
                        </div>
                    </div>
                </ContainerDropDown>
            </div>
        )
    }
}

export default SelectDropDown;
