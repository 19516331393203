/* Create Horizontal Card Car Component */
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './index.scss';
import { CalendarToday, CardGiftcardOutlined, FavoriteBorderOutlined, FavoriteOutlined, FiberManualRecord, LocalGasStation, LocationOn, PowerInput, Speed } from '@mui/icons-material';
import { useNavigate } from "react-router-dom";
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { connect } from 'react-redux';
import ConnectionAPI from '../../../api';
import Button from '../../Button';
import UrlNavigatorSingleton from '../../../lib/UrlNavigator';
import { formatNumber } from '../../../../utils/number.tsx';
import ImageDisplayer from '../../ImageDisplayer/index.js';
import { useTranslation } from 'react-i18next';
import { pixelUserFavorite } from '../../../../utils/pixel.js';
import { isMobile } from '../../../../utils/utils.js';

const URL_CAR_STRIPE = process.env.REACT_APP_STRIPE_URL;

const CardHorizontal = ({car, isOrder, user}) => {
    /* Use Navigation to go to the Car Page */
    let carStatus = null;
    if(isOrder){
        carStatus = car.status;
        car = car.car;
    }
    let [isFavorite, setIsFavorite] = useState(false);

    const navigate = useNavigate();
    
    const getCarDetails = async () => {
        /* Get My Car Details */
        let response_my_car = await ConnectionAPI.myCarDetails({id : car.id});
        setIsFavorite(response_my_car.message.isFavorite);
    }

    const toCarPage = () => {
        if(car.available)
            //window.open(`${window.location.origin}/car?id=${car.id}`);
            navigate(`/car?id=${car.id}`);
    }
    
    const addFavorite =  async () => {
        /* Change Favorite Icon */
        await ConnectionAPI.addFavorite({user_id : user.id, id : car.id});
        if(!isFavorite){
            //this means it is going to be favorite (the user clicked on the favorite button when the car was not favorite)
            pixelUserFavorite(user.name,user.email,car.id);
        }
        setIsFavorite(!isFavorite);
    }

    /* Component Did Mount verify if car is Favorite */
    useState(() => {
        if(user && user.id){
            getCarDetails();
        }
    }
    ,[]);

    const toPaymentPage = (id) => {
        UrlNavigatorSingleton.__toPage(`${URL_CAR_STRIPE}?id=${id}&prefilled_email=${user.email}`)
    }
    
    const totalPrice = parseInt(parseFloat(car.price) + (parseFloat(car.price)*parseFloat(car.vat)/100));

    const characteristicTotalNumber = car.other.length + car.security.length + car.multimedia.length +
                                    car.interior.length;
    const { t, i18n } = useTranslation();

    const mobile = isMobile(window);
    let orderStatusElem = (carStatus ? 
        <div className='absolute-container flex items-center'>
            <h6 className='bold-text'>{t('order_status')}</h6>
            <div className='tag right no-margin !pt-0 !pb-0 !pl-1 !pr-1'>
                <h6>{carStatus}</h6>
            </div>
        </div>
    : <></>);

    return (
         <div className='horizontal-card'>
            <div className='image stack-top'>
                {
                    mobile ?
                    <div className='details-absolute'>
                        {/* Favorite Button */}
                        <button onClick={addFavorite} className='box-container no-margin no-padding icon'>
                            {isFavorite ? 
                                <FavoriteOutlined width={30} className='no-margin'/>
                            :   <FavoriteBorderOutlined width={30} className='no-margin'/> 
                            }
                        </button>
                    </div>
                    :
                    <></>
                }
                
                <ImageDisplayer className="absolute-carousel" 
                    imageOnClick={toCarPage}
                    imageUrls={car.photos} imageClasses="carousel-image" 
                    showThumbs={false} showStatus={false} showArrows={true}/>
            </div>
            <div className='content !h-full' onClick={toCarPage}>
            <div className={`${mobile ? 'block' : 'flex justify-between'}`}>
                    <h4 className='flex-1 text-left bold-text'>{car.make} {car.model}</h4>
                     {/* Order Status */}
                    {orderStatusElem}
                    {
                        mobile ?
                        <></>
                        :
                        <div className='details-absolute'>
                            <button onClick={addFavorite} className='box-container no-margin no-padding icon'>
                                {isFavorite ? 
                                    <FavoriteOutlined width={30} className='no-margin'/>
                                :   <FavoriteBorderOutlined width={30} className='no-margin'/> 
                                }
                            </button>
                        </div>
                    }      
                </div>
                     
                {/* Car Details */}
                <div className='tags'>
                    <div className='inline'>
                        <Speed className='icon inline grey'/>
                        <h5 className='grey inline'>{formatNumber(car.mileage)} km</h5>
                    </div>
                    <div className='inline'>
                        <CalendarToday className='icon inline grey' />
                        <h5 className='grey inline'>{car.year}</h5>
                    </div>
                    <div className='inline'>
                        <PowerInput className='icon inline grey' />
                        <h5 className='grey inline'>{car.power} hp</h5>
                    </div>
                    <div className='inline'>
                        <LocalGasStation className='icon inline grey' />
                        <h5 className='grey inline'>{car.CarFuel[i18n.language]}</h5>
                    </div>
                </div>
                {mobile
                ?
                <></>
                :
                    <div className='tags characteristics'>
                        {car.interior.map((characteristic, index) => {
                            if (index <= 4)
                                return <h6 key={index} className='inline tag'>{characteristic[i18n.language + '_translation']}</h6>
                            else return <></>
                        })}
                        {
                            <h6 className='inline tag'>{"+" + (characteristicTotalNumber - 4) + " others"}</h6>
                        }
                    </div>
                }
                <div className='grid'>
                    <div className='left'>
                        <div className='inline'>
                            <LocationOn className='icon inline grey'/>
                            <h5 className='grey inline'>{car.Country[i18n.language]}</h5>
                        </div>
                        <div className={`tag ${car.available ? 'green' : 'red'} available`}>
                            <FiberManualRecord className='inline'/>
                            <h6 className='inline'>{car.available ? t('available') : t('unavailable')}</h6>
                        </div>
                    </div>
                    <div className='right'>
                        <div className='small-container'></div>
                        <h4 className='bold-text text-right'>{formatNumber(totalPrice)} €</h4>
                        {/* <h5 className='grey'>{formatNumber(car.priceWithoutVat)} € {t('without_vat')}</h5> */}
                    </div>
                </div>
            </div>
        </div>
    )
}

function mapStateToProps(state) {
    const user = state.user;
    return {
        user
    };
}

export default connect(mapStateToProps)(CardHorizontal);